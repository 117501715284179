import { Component, DestroyRef, effect, inject, input, model, OnDestroy, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { distinctUntilChanged } from 'rxjs';
import { fadeIn } from '../../animations/fadeIn';
import { LoaderService } from './loader.service';

@Component({
  selector: 'lib-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeIn],
  standalone: false,
})
export class LoaderComponent implements OnDestroy {
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly loader = inject(LoaderService);

  DEFAULT_LOADER_TIMEOUT = 20000; // 20 seconds.

  /** Show load spinner when `isLoading` is true. */
  isLoading = model(false);
  /** The size of the spinner */
  diameter = input(30);
  /** The bold-ness of the spinner */
  strokeWidth = input(4);
  /** If false, it will try to overlay on the parent element */
  inline = input(false);
  /** A tooltip text will be shown if the load spinner is still spinning after this set time */
  timeout = input(this.DEFAULT_LOADER_TIMEOUT);
  /** The tooltip text to display */
  timeoutExplanation = input('It is taking longer than expected to complete this task. Please be patient.');

  private tooltip = viewChild(MatTooltip);
  private _destroyed = false;

  /**
   * If global loader is enabled, the `isLoading` state will be controlled by the
   * loader.interceptor and will show a load spinner on http requests.
   */
  useGlobalLoader = input(false);
  private globalLoader$ = this.loader.isLoading$().pipe(takeUntilDestroyed(this.destroyRef$), distinctUntilChanged());
  private globalLoader = toSignal(this.globalLoader$);
  private _timeout: NodeJS.Timeout | undefined = undefined;
  private _tooltipTimeout: NodeJS.Timeout | undefined = undefined;

  private globalLoaderEffect = effect(() => {
    if (this.useGlobalLoader()) {
      const on = this.globalLoader();

      if (on === true) {
        // Start loading
        this.isLoading.set(true);

        // Clear any existing tooltip timeouts
        if (this._tooltipTimeout) {
          clearTimeout(this._tooltipTimeout);
        }

        // Set a new timeout to show the tooltip after the specified delay
        this._tooltipTimeout = setTimeout(() => {
          if (!this._destroyed && this.isLoading()) {
            this.tooltip()?.show();
          }
        }, this.timeout());
      } else {
        // Clear the tooltip timeout when loading stops
        if (this._tooltipTimeout) {
          clearTimeout(this._tooltipTimeout);
          this._tooltipTimeout = undefined;
        }

        // Add a small delay before hiding the spinner for visual smoothness
        if (this._timeout) {
          clearTimeout(this._timeout);
        }
        this._timeout = setTimeout(() => {
          this.isLoading.set(false);
          this.tooltip()?.hide();
        }, 100);
      }
    }
  });

  get size() {
    return `${this.diameter()}px`;
  }

  ngOnDestroy(): void {
    if (this._tooltipTimeout) {
      clearTimeout(this._tooltipTimeout);
    }
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this.isLoading.set(false);
    this.tooltip()?.hide();
    this._destroyed = true;
  }
}
