import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, Input, OnInit, output } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from './language.service';

@Component({
  selector: 'lib-language-selector',
  imports: [CommonModule, MatFormFieldModule, MatIconModule, MatButtonToggleModule, MatSelectModule, TranslateModule],
  template: `
    <mat-form-field *ngIf="!flat(); else toggles">
      <mat-icon matPrefix *ngIf="withIcon()">language</mat-icon>
      <mat-select
        [value]="value"
        (selectionChange)="setLanguage($event)"
        [disabled]="disabled()"
        panelClass="selection">
        <mat-option *ngFor="let option of supportedLanguageOptions" [value]="option.code">
          {{ option.language }}
        </mat-option>
      </mat-select>
      <ng-content></ng-content>
    </mat-form-field>

    <ng-template #toggles>
      <mat-button-toggle-group [value]="value" (change)="setLanguage($event)" [disabled]="disabled()">
        <mat-button-toggle *ngFor="let option of supportedLanguageOptions" [value]="option.code">
          {{ option.language }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;

        ::ng-deep .mat-form-field.mat-form-field-type-mat-select {
          max-height: 2.5rem;
          overflow: hidden;
        }
      }

      .dialog-title ::ng-deep .mat-optgroup-label {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit {
  private readonly languageService = inject(LanguageService);

  flat = input<boolean>(false);
  disabled = input<boolean>(false);
  withIcon = input<boolean>(true);

  langChanged = output<string>();

  _value!: string;
  @Input()
  set value(value: string) {
    this._value = value?.toLowerCase();
  }

  get value() {
    return this._value;
  }

  supportedLanguageOptions = this.languageService.supportedLanguageOptions;

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.value) {
        // Set a default value
        this.setLanguage({ value: this.languageService.current } as MatSelectChange);
      }
    });
  }

  setLanguage($event: MatSelectChange | MatButtonToggleChange) {
    this.value = $event.value;
    this.langChanged.emit(this.value);
  }
}
