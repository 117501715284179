import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private _loaders$ = new BehaviorSubject(5);
  get processes() {
    return this._loaders$.value;
  }

  startLoad(n = this.processes + 1): void {
    this._loaders$.next(n);
  }

  stopLoad(n = this.processes - 1): void {
    if (this.processes > 0) {
      this._loaders$.next(n);
    }
  }

  forceStopAll(): void {
    this._loaders$.next(0);
  }

  isLoading$(): Observable<boolean> {
    return this._loaders$.pipe(
      switchMap((n) => of(n > 0)),
      // debounceTime(200),
      distinctUntilChanged(),
    );
  }
  isLoading(): boolean {
    return this._loaders$.value > 0;
  }
}
